//@flow
import React, { memo } from 'react';

import { Card, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { styles } from './AssetGroupCard';

export default memo<{||}>(function AssetGroupCardLoadingSkeleton() {
  return (
    <Card style={{ ...styles.assetCard, minHeight: 60 }}>
      <Grid
        container
        alignItems="flex-start"
        justify="space-between"
        wrap="nowrap"
        spacing={2}
      >
        <Grid item>
          <Skeleton variant="circle" animation="wave" width={40} height={40} />
        </Grid>
        <Grid item container direction="row" spacing={2} wrap="nowrap">
          <Grid item xs={8}>
            <Skeleton animation="wave" width={365} />
            <Skeleton animation="wave" width={250} />
          </Grid>
          <Grid
            item
            container
            direction="row"
            spacing={4}
            justify="flex-end"
            wrap="nowrap"
            alignItems="flex-start"
          >
            <Grid item>
              <Skeleton variant="rect" animation="wave" width={40} />
            </Grid>

            <Grid item>
              <Skeleton variant="rect" animation="wave" width={40} />
            </Grid>

            <Grid item>
              <Skeleton variant="rect" animation="wave" width={40} />
            </Grid>

            <Grid item style={{ paddingRight: 40 }}>
              <Skeleton variant="rect" animation="wave" width={40} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
});
