// @flow
import '@dt/global';
import React, { memo } from 'react';
import { Provider } from 'react-redux';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  default as SchemaRestLink,
  ApolloLinkSchemaRestError,
} from '@dt/apollo-link-schema-rest';
import Raven from 'raven-js';
import { TrackingProvider } from '@dt/analytics';
import { materialTheme } from '@dt/theme';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import fetch, { parse } from '@dt/fetch';
import config from '@dt/config';
import schema from '@dt/graphql-support/restSchema.graphql';
import getMockResolvers from '@dt/graphql-support/getMockResolvers';
import type { ResolverMap } from 'graphql-tools';
import { RequiresUserAccountOrRedirect } from '@dt/session';
import { CenteredCircularProgress } from '@dt/material-components';
import { type Store } from './redux';
import ApplicationAuthenticated from './ApplicationAuthenticated';
import WaitForSession from './wait-for-session';

type Props = {
  graphQLMocks: void | ResolverMap,
  store: Store,
};

function Application({ store, graphQLMocks }: Props) {
  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, response, operation }) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            // Log all errors.
            console.error(
              `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
            );

            // Handle apollo link schema rest errors - These are not recorded as network errors.
            // If any are backend 5** status codes report to sentry.
            if (
              error.originalError &&
              error.originalError instanceof ApolloLinkSchemaRestError &&
              error.originalError.statusCode >= 500 &&
              error.originalError.statusCode < 600
            ) {
              Raven.captureException(error, {
                extra: {
                  msg: `Backend reported ${error.originalError.statusCode} status code when executing operation '${operation.operationName}'.`,
                },
              });

              // Format 5** response error messages to a friendlier message.
              error.message =
                'We were unable to complete your request at this time. \nPlease try again or contact us at support@datatheorem.com';
            }
          }
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }

        response.errors = graphQLErrors;
      }),
      SchemaRestLink({
        endpoints: {
          horizon: `${config.horizonAPI}/public`,
          sevenhell: `${config.sevenhellAPI}/_ah/api/userapi`,
          echoserver: `${config.apiProxy}/apis/portal/echo`,
          googleStorage: `${config.googleStorage}/prod-echo_scan_run_logs/1030002`,
        },
        schema,
        fetch,
        fetchParse: parse,
        mockResolvers: getMockResolvers(graphQLMocks),
      }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        // NOTE: DO NOT change `fetchPolicy` or `errorPolicy` there are some nasty dragons here.
        //       To learn more see: https://datatheorem.atlassian.net/wiki/spaces/IW/pages/1297351093/FrontEnd+GraphQL
        errorPolicy: 'all',
      },
    },
  });

  let muiTheme = createMuiTheme(materialTheme());

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={muiTheme}>
          <TrackingProvider>
            <RequiresUserAccountOrRedirect>
              {({ userAccount }) => {
                if (!userAccount) {
                  return <CenteredCircularProgress />;
                }

                return (
                  <WaitForSession fallback={<CenteredCircularProgress />}>
                    <ApplicationAuthenticated
                      accountInfo={userAccount.accountInfo}
                    />
                  </WaitForSession>
                );
              }}
            </RequiresUserAccountOrRedirect>
          </TrackingProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    </Provider>
  );
}

export default memo<Props>(Application);
