//@flow
import React, { memo } from 'react';

import { styles } from './AssetGroupCard';
import { palette } from '@dt/theme';
import { Card, Grid } from '@material-ui/core';
import { Text } from '@dt/material-components';
import ErrorIcon from '@material-ui/icons/Error';

export default memo<{||}>(function AssetGroupCardZeroState() {
  return (
    <Card style={styles.assetCard}>
      <Grid container alignItems="center" wrap="nowrap" spacing={2}>
        <Grid item>
          <ErrorIcon style={{ width: 50, height: 50 }} />
        </Grid>
        <Grid item container direction="row" spacing={2} wrap="nowrap">
          <Grid item>
            <Text color={palette.gray20} variant="body">
              No Asset Groups were found. Please click the button at the top of
              this page to add one.
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
});
