// @flow

import React from 'react';
import { Card, Typography, Grid, Avatar, Tooltip } from '@material-ui/core';
import { palette } from '@dt/theme';
import { Link } from '@reach/router';
import DatatheoremLogo from '@dt/brand/logo.png';
import PolicyIcon from '@material-ui/icons/AssignmentTurnedIn';
import type { PolicyDecorated } from '@dt/horizon-api';
import type { PoliciesContainerQueryAhura_policies_list_policies } from '@dt/graphql-support/types/PoliciesContainerQueryAhura';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = {
  assetCard: {
    marginBottom: 24,
    padding: 16,
  },
  avatar: { background: palette.brand35 },
  contentContainer: {},
  violationTagStrip: {
    display: 'flex',
  },
  urlWrap: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
};

type Props = {|
  policy?: PolicyDecorated | PoliciesContainerQueryAhura_policies_list_policies,
  noLink?: boolean,
  isLoading?: boolean,
|};

const PolicyCard = ({ policy, noLink, isLoading }: Props) => {
  const card = (
    <Card style={styles.assetCard}>
      <Grid container alignItems="flex-start" wrap="nowrap" spacing={2}>
        <Grid item>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="circle"
              height={40}
              width={40}
            />
          ) : (
            <Avatar style={{ backgroundColor: palette.gray45 }}>
              <PolicyIcon style={{ color: palette.gray20 }} />
            </Avatar>
          )}
        </Grid>
        <Grid item container direction="row" spacing={1}>
          <Grid item>
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={100} />
            ) : (
              <Typography
                variant="body1"
                component="div"
                style={styles.urlWrap}
              >
                {policy.name}{' '}
                {policy.is_special_dt_policy && (
                  <Tooltip title="This policy has been crafted by Data Theorem's security experts.">
                    <img
                      style={{
                        width: 18,
                        height: 18,
                        verticalAlign: 'middle',
                        objectFit: 'cover',
                      }}
                      src={DatatheoremLogo}
                    />
                  </Tooltip>
                )}
              </Typography>
            )}
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={170} />
            ) : (
              <Typography variant="body2">
                Created by{' '}
                {policy.created_by_user &&
                  `${policy.created_by_user.first_name} ${policy.created_by_user.last_name}`}{' '}
                on {new Date(policy.date_created).toLocaleDateString()}
              </Typography>
            )}
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={120} />
            ) : (
              <Typography variant="body2">
                {policy.enabled_policy_rules_count} rules enabled
              </Typography>
            )}
            {isLoading || !policy ? (
              <Skeleton animation="wave" width={160} />
            ) : (
              <Typography variant="body2">
                {policy.protected_asset_groups_count} asset group
                {policy.protected_asset_groups_count !== 1 ? 's' : ''} protected
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
  return noLink || !policy ? (
    card
  ) : (
    <Link to={`/api/policy/${policy.id}`}>{card}</Link>
  );
};

export default React.memo<Props>(PolicyCard);
